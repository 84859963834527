@import "../../../assets/style/commonStyle.scss";

.title-wrap{
    padding: 10px;
}

.search-section {
    display: flex;
    justify-content: right;
    padding: 1% 4%
}

.search-wrap {
    width: 30%;
}

.table-wrap{
    padding: 1% 4%
}
