.alert-wrapper{
    border-style: solid;
    border-width: 1px;
    border-color: #636060;
    padding: 10px;
    border-radius: 5px;
    
}

.check-circle-icon{
    text-align: center;
    margin: 15px;
}
.check-circle-icon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.title-text{
    text-align: center;
    padding: 10px
}

.single-button{
    display: flex;
    justify-content: center;
    min-height: 100px;
    align-items: center;
}

.single-button button {
    min-width: 200px;
    height: 40px;
}

.double-button{
    display: flex;
    padding-top: 10px;
    justify-content: space-around;
    min-height: 100px;
    align-items: center;
}
.description{
    text-align: center;
}
