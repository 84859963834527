@import "../../../assets/style/commonStyle.scss";
@import "../../PrivateUsers/styles/style.scss";


.pc-main-content-wrap   {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.form-section{
    display: flex;
    justify-content: space-around;
    padding: 5%;
}
.form-content-wrap{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.button-group-wrap{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    // background-color: #ff000000;
}
