.img-section{
    display: flex;
    justify-content: center;
}

.img-section .img-wrap img {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.no-of-user{
    display: flex;
    align-items:center;
    justify-content: center;

    // gap: 5px;
}

.button-group{
    display: flex;
    justify-content: center;
    gap: 15px;

}

.current-subscription-wrap{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.no-of-user{
    display: flex;
    gap: 10px;

}