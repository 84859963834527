.content-container{
    display: flex;
    justify-content: center;
    // background-color: antiquewhite;
    // height: inherit;
}
.content-wrap{
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #ffffff;
    width: 95%;
    border-radius: 10px;
    min-height: 500px;
    box-shadow: -8px 8px 14px 0 rgba(25, 42, 70, 0.11);
}