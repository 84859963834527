@import "../../../assets/style/commonStyle.scss";
@import "../../PrivateUsers/styles/style.scss";

.edit-btn-container {
    display: flex;
    justify-content: flex-end;
}
.edit-btn-wrap {
   
}
.button-group-wrap{
    display: flex;
    justify-content: space-between;

}
.company-logo img {
    object-fit: cover;
    width:280px;
    height:280px;
    border-radius: 3%;
}
.ci-form-content-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form-content-section{
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 300px;

}

