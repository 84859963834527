.layout {
    height: 100vh;
    display: flex;
    justify-content: center;
}

.content-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f7f7f7;
}
