.box-wrap {
    padding: 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right bottom, #051937, #003757, #005773, #0e798a, #3b9b9c);
}

.align-text {
    min-width: 180px;
    color:#fff;
}


.text-bg {
    background-color: #2b717261;
    border-radius: 2px;
}

.top-section{
    display: flex;
    justify-content: space-between;
}

.top-section .img-wrap img {
    object-fit: contain;
    width: 30px;
    height: 30px;
}