// For small screens (mobile view)
@media screen and (min-device-width: 320px) and (max-device-width: 990px) {
    .change-pw-logo {
        display: none;
    }

}
// @media screen and (min-device-width: 320px) and (max-device-width: 740px) {
//     .change-pw-logo {
//         display: none;
//     }

// }