// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    height: auto;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(./assets/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(./assets/font/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(./assets/font/Poppins/Poppins-Light.ttf);
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(./assets/font/Montserrat/static/Montserrat-Regular.ttf);
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    font-family: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #342E49;
    text-align: left;
}

.remove-psudo-elements {

    &::before,
    &::after {
        display: none;
    }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-family: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #17102F; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.w-100 {
    width: 100%;
}

textarea {
  resize: none;
}