.img-group{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;

}
.sidebar-logo-img{
    object-fit: contain;
    width: 40px;
    height: 100px;


}
.sidebar-text-img{
    object-fit: contain;
    width: 150px;
    height: 150px;

}
.sidebar-logo {
    object-fit: contain;
    width: 195px;
    height: 195px;
}
