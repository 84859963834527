.button-n-search-section {
   display: flex;
   justify-content: center;
}
.text-info-section {
   display: flex;
   justify-content: flex-end;
   // padding-left: 2.1%;
   padding-right: 4%;
}
.text-wrap p {
   text-align: left;
}
