.input-phone-number input:focus{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
}

.input-phone-number input{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.error-text {
    color: #e30922;
    font-size: 15px;
}


