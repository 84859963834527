@import '../../../style/style.scss';

.form-content-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.btn-group-wrap{
    display: flex;
    justify-content: space-between;
}
.logo-wrap {
    padding: 10px;
}

