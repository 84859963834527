@import "../../../assets/style/commonStyle.scss";
// @import "../../PrivateUsers/styles/style.scss";

.add-n-search-section{
    display: flex;
    justify-content: center;
    // width: 80%;
    // background-color: antiquewhite;
}
.add-n-search-content-wrap{
    // text-align: right;
    display: flex;
    justify-content: space-between;
    align-content: center;
    // flex-direction: column;
    width: 92%;
    // background-color: rgb(172, 105, 17);
    // gap: 10px;
    // padding-right: 4%;
}

