
.form-control-date {
    display: block;
    width: 220px;
    height: calc(1.5em + 0.75rem + 3px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #75787d;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #E0E0E0;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control-date {
        transition: none; } }
    .form-control-date::-ms-expand {
      background-color: transparent;
      border: 0; }
    .form-control-date:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #75787d; }
    .form-control-date:focus {
      color: #75787d;
      background-color: transparent;
      border-color: #975AFF;
      outline: 0;
      box-shadow: none; }
    .form-control-date::placeholder {
      color: #BDBDBD;
      opacity: 1; }
    .form-control-date:disabled, .form-control-date[readonly] {
      background-color: #F7F7F8;
      opacity: 1; }
  


.error-text {
    color: #e30922;
    font-size: 15px;
}
